<template>
  <div :style="`font-family:${font},sans-serif !important`">
    <!-- <div>
      <p>{{ this.$route.params.id }}</p>
    </div> -->
    <!-- {{ this.selectedG }} -->
    <div class="card col-12 col-md-12 p-1">
      <div>
        <div class="row">
          <div class="col-4 mt-1" style="text-align: start">
            <b-button variant="gradient-secondary" class="mr-1" @click="$router.push({ name: 'counting' })">
              กลับ
            </b-button>
          </div>
          <div class="col-4 mt-1" style="text-align: center">
            <div class="title head">
              <p class="h2" style="color: #558cef">รายการตรวจนับทรัพย์สิน</p>
            </div>
          </div>
          <div class="col-4 mt-1" style="text-align: end">
            <!-- <b-button> <feather-icon icon="FileIcon" class="mr-50" /> <span>Excel</span></b-button> -->

            <!-- <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <b-button variant="outline-primary">
                  <feather-icon icon="ShareIcon" class="mr-50" />Export/Import
                  <feather-icon icon="ChevronDownIcon" class="mr-50" />
                </b-button>
              </template>

              <b-dropdown-item @click="exportExcel">
                <feather-icon icon="FileIcon" class="mr-50" />
                <span>Export Excel</span>
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.modal-Import>
                <feather-icon icon="FileIcon" class="mr-50" />
                Import Excel
              </b-dropdown-item>
            </b-dropdown> -->
          </div>
        </div>
        <b-modal id="modal-Import" title="Import Excel" ok-title="ตกลง" cancel-title="ยกเลิก" no-close-on-backdrop>
          <b-card-text>
            <h5>นำเข้าไฟล์ Excel</h5>
            <b-form-file
              type="file"
              @change="onFileChange"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            />
          </b-card-text>
        </b-modal>
        <div>
          <hr />
          <div class="row justify-content-between">
            <div class="row">
              <!-- {{ selectedG }} -->
              <div v-if="status_admin == 'แอดมิน' && selectedG != 'เลือกกลุ่มทรัพย์สิน' && selectedG != null">
                <b-form-group class="col-auto" label-cols="auto" label-cols-lg="auto">
                  <b-button class="btn btn-success" @click="post_data">บันทึก</b-button>
                </b-form-group>
              </div>

              <b-form-group class="col-auto" label-cols="auto" label-cols-lg="auto">
                <b-button class="btn btn-danger" @click="getfrom" v-b-modal.modal-qrcode>
                  <feather-icon icon="GridIcon" class="mr-1" />สแกนคิวอาร์โค้ด
                </b-button>
              </b-form-group>
              <!-- <b-form-group class="col-auto" label-cols="auto" label-cols-lg="auto">
                <b-button class="btn btn-gradient-secondary" > เลือกรายการตรวจนับ </b-button>
              </b-form-group> -->
              <!-- {{ rows }} -->
            </div>
            <b-modal id="modal-qrcode" title="สแกนคิวอาร์โค้ด" ok-title="ปิด" cancel-title="xx">
              <b-card-text>
                <p class="error">{{ error }}</p>
                <p class="decode-result">
                  Last result: <b>{{ result }}</b>
                </p>
                <qrcode-stream @decode="onDecode" @init="onInit" />
              </b-card-text>
            </b-modal>
            <b-form-group class="mr-1 col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input
                  @input="search_data(searchTerm)"
                  v-model="searchTerm"
                  placeholder="ค้นหาจากรายการ หมายเลขทรัพย์สิน"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </div>
        <div v-if="status_admin == 'แอดมิน'">
          <!-- admin -->
          <!-- {{ selectedG }} -->
          <b-form-group label-cols="3" label-cols-lg="2" label="กลุ่มทรัพย์สิน:">
            <v-select
              placeholder="เลือกกลุ่มทรัพย์สิน"
              v-model="selectedG"
              label="e_gname"
              :options="selectedGs"
              @input="item_select($event)"
            />
          </b-form-group>
        </div>

        <!-- data -->
        <div v-if="status_admin == 'แอดมิน'">
          <div class="row">
            <b-button class="col-auto ml-1 mr-1" variant="primary" @click="ch1_k(rows)">
              <div v-if="ckn1 == false"><feather-icon icon="CircleIcon" class="mr-50" />เลือกทั้งหมด</div>
              <div v-else><feather-icon icon="CheckCircleIcon" class="mr-50" />เลือกทั้งหมด</div>
            </b-button>
            <b-button class="col-auto ml-1 mr-1" variant="danger" @click="ckn2_k(rows)">
              <div v-if="ckn2 == false"><feather-icon icon="CircleIcon" class="mr-50" />รายการที่ยังไม่ตรวจนับ</div>
              <div v-else><feather-icon icon="CheckCircleIcon" class="mr-50" />รายการที่ยังไม่ตรวจนับ</div>
            </b-button>

            <div v-if="selectedG == 'เลือกกลุ่มทรัพย์สิน' || selectedG == null"></div>
            <div v-else>
              <b-button class="col-auto ml-1 mr-1" variant="warning" @click="ckn3_k(rows)">
                <div v-if="ckn3 == false">
                  <feather-icon icon="CircleIcon" class="mr-50" />รายการที่ยังไม่อยู่ใน Job
                </div>
                <div v-else><feather-icon icon="CheckCircleIcon" class="mr-50" />รายการที่ยังไม่อยู่ใน Job</div>
              </b-button>
            </div>

            <div v-if="selectedG == 'เลือกกลุ่มทรัพย์สิน' || selectedG == null">
              <b-button class="col-auto ml-1 mr-1" variant="success" @click="ckn5_k(rows)">
                <div v-if="ckn5 == false"><feather-icon icon="CircleIcon" class="mr-50" />รายการที่ตรวจนับเเล้ว</div>
                <div v-else><feather-icon icon="CheckCircleIcon" class="mr-50" />รายการที่ตรวจนับเเล้ว</div>
              </b-button>
            </div>

            <div v-if="selectedG == 'เลือกกลุ่มทรัพย์สิน' || selectedG == null">
              <b-button class="col-auto ml-1 mr-1" @click="ckn4_k(rows)" v-b-modal.modal-2>
                <div v-if="ckn4 == false">ย้ายรายการที่เลือกทั้งหมด</div>
                <div v-else>ย้ายรายการที่เลือกทั้งหมด</div>
              </b-button>
            </div>

            <b-button class="col-auto ml-1 mr-1" variant="danger" @click="condel(rows, 'ยกเลิกที่เลือกทั้งหมด')">
              ยกเลิกที่เลือกทั้งหมด
            </b-button>
          </div>

          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns3"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
              :select-options="{
                disableSelectInfo: true,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'remark'">
                  <span>
                    <b-input
                      v-model="props.row.remark"
                      @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                    />
                  </span>
                </span>
                <div v-else-if="props.column.field === 'vgtSelected'">
                  <div class="row m-0">
                    <div class="col-3 col-md-3">
                      <!-- {{ props.row.vgtSelected }} -->
                      <b-form-checkbox
                        class="row"
                        v-model="props.row.vgtSelected"
                        @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                      >
                        เลือก
                      </b-form-checkbox>
                    </div>
                    <!-- <div v-if="props.row.checker_name">
                      <b-button variant="danger" @click="condel(props.row, 'ลบรายการ')">ยกเลิก</b-button>
                    </div> -->
                    <div class="col-9 col-md-9 p-0">
                      <div class="list" v-for="(items, index) in props.row.checker_name" :key="index">
                        <span class="row pl-1 pr-1">
                          <h5>{{ index + 1 }}.ID Job: {{ props.row.job_code }} {{ items }}</h5>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="props.column.field === 'คนตรวจนับ'">
                  <b-button v-b-modal.modal-1>รายการ</b-button>
                </div>

                <div v-else-if="props.column.field === 'yay'">
                  <div v-if="selectedG == null || selectedG == 'เลือกกลุ่มทรัพย์สิน'">
                    <b-button v-b-modal.modal-2 @click="get_yay(props.row)">ย้ายรายการตรวจนับ</b-button>
                  </div>
                </div>
                <div v-else-if="props.column.field === 'delete_list'">
                  <b-button variant="danger" @click="condel(props.row, 'ยกเลิกรายการ')">ยกเลิกรายการ</b-button>
                </div>

                <div v-else>
                  {{ props.formattedRow[props.column.field] }}
                </div>
              </template>

              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
            <!-- <div class="row">
              <div class="col-12 col-md-4" v-for="rows in rows" :key="rows">
                <b-card style="border: 1px solid #558cef">
                  <div class="text-center">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAASFBMVEXn5+eUlJTq6uqRkZGVlZXi4uLm5ubNzc2ZmZnd3d3R0dG1tbXU1NTHx8e+vr6jo6OdnZ2pqamtra3Z2dmLi4uzs7PIyMjBwcGP1gM/AAADj0lEQVR4nO3X65KjOAyAUWRhYy4JkITk/d90JQNJemprenarpsOP71SFQDdOWUi+UFUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADAUYQQ3i9+rO1fFtPWnZC6pqv2i6Fp2m/7meLzlqHp9ovQ/knbHxP6sSm9CcMoKvO1XKRLrZrv37U95dN60s7WdurWsM7Z2p7/Yp//m1Z0Lh1rs9RTLWP0i0XrnEWb32cijjKVkzSqt5XWzsNZxdueD5LFMEs9lJNe5BTOpWdhULmk1sJNv23bq949jnBS6UMjsthFrGWKcZI6/kT/vxUa3R52sgq1k0kmi/Ai2gYLV7o9EetE8mU6qa61rOkPk+TKW0mswkMt9fa78jhEEq3OcpkgPG2evUXkGuyvlr0wvEotdl0Xq1SOO4uoHvYCvwTPpMd2kcmKtZ38T5/nD/wR21gKTbvSS2mC9Xirt72X15veTqGx4yur9kyWtW1X77VtX1Op7bQP0Q+zUKZqsr5XPgKHUrVyD1Z+vQWSbCDuEYpFWy12HPa2FkNuL7fF2jai9hNV66OxyjL7v2cv3I+zmtR7mrx7NtFo6+mwpIZrSUYV6np6Zqy3krTUPgeiJ70PF/ts5Wk5V8/5Op5tBtNPhfUSBk9S+0cRVklkXJeDlQ20KaX54BFanQ026L5GKP8eoZXxWrvbpd1/D7YOeoT3Z4TLoSL0aOYUh6yPtI7D0lkbhzY8t3E4v+bDNtf1K4XXXOcULZFLeo7D6zYOR79hPMA4LFlR1bq2RKzLmE+utgb67G832JK/vJJ2sruf+xSbkaxZaTsGm0vLSlMq3OfSWJah8TNhvQn9TXWPcEuAFZet2skmwjU1r1XbdgST6L4a+sKhe4RVzD7prmVgs1e+eo7fns7HpGiS1eXDN2fWy+jZ8772vjiWFX2/13abeZj0ORBL22iPxaq0PJc2+PIRS52ffT+0bugOwPdtPoi8Pvurbbx8zvAt6XCSUqtFHOzCt67d+041rCug1afYS0m/bYFsC9c0+RgLvtsjtLlB7Q1IS1Aermopt/Ume3ewrA7l+NZ4i9DfRaytLR/lB8XbyjfvJT8n3G+3NcLU5+drXbhPqvPzNdYiVOu+fd2+Rni7baPtkbVe1vyGYVYdh6ME+C5sn1/P/09bAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHN4/HskdrpeKiZEAAAAASUVORK5CYII="
                      max-width="225"
                    />
                  </div>
                  <br />
                  <div>
                    <span><b>หมายเลขทรัพย์สิน :</b> {{ rows.e_number }}</span> <br />
                    <span><b>รายการ :</b> {{ rows.information }}</span>
                  </div>

                  <br />
                  <b-button> ตรวจนับ </b-button> <b-button> เลือกรายการ </b-button>
                </b-card>
              </div>
            </div> -->
          </b-overlay>
          <!-- <b-modal id="modal-1" title="รายการคนตรวจนับ" ok-only ok-title="ปิด">
            <b-card-text>
              <div class="row m-1">
                <h5>1.(ID Job : 123) นายณัฐวุฒิ โพธิ์สีดา</h5>
                <b-button class="ml-1" size="sm" variant="danger">ยกเลิก</b-button>
              </div>

              <div class="row m-1">
                <h5>2.(ID Job : 123) นายณัฐวุฒิ โพธิ์สีดา</h5>
                <b-button class="ml-1" size="sm" variant="danger">ยกเลิก</b-button>
              </div>
              <div class="row m-1">
                <h5>3.(ID Job : 123) นายณัฐวุฒิ โพธิ์สีดา</h5>
                <b-button class="ml-1" size="sm" variant="danger">ยกเลิก</b-button>
              </div>
            </b-card-text>
          </b-modal> -->
          <b-modal id="modal-2" title="ย้ายรายการตรวจนับ" ok-only ok-title="ตกลง" @ok="get_yay_connect(selectedY)">
            <v-select placeholder="เลือกกลุ่มตรวจนับ" v-model="selectedY" label="e_gname" :options="selectedYs" />
            <!-- {{ yay_data }} -->
            <div class="m-1 text-center"><b>ชื่อ Job:</b> {{ this.$route.params.id.code }}</div>

            <div class="p-1" v-for="(data, index) in yay_data" :key="index">
              <b>ID Job: </b> {{ data.job_code }} <br />
              <b>ชื่อทรัพย์สิน: </b> {{ data.infoadmit }} <br />
              <b>หมายเลขทรัพย์สิน: </b> {{ data.e_number }}
            </div>

            <br />
            <div v-if="selectedY != 'เลือกย้ายรายการตรวจนับ'">
              <div class="text-center h-5"><b>ย้ายไป Job ใหม่</b></div>
              <div class="p-1">
                <!-- {{ selectedY }} -->

                <b>ID Job:</b> {{ selectedY.job_code }} <br />
                <b>ชื่อ Job:</b> {{ selectedY.code }} <br />
                <div class="row pl-1">
                  <div col-6>
                    <b>ผู้ตรวจนับ: </b>
                  </div>
                  <div col-6>
                    <div v-for="(data, index) in selectedY.checker_name" :key="index">{{ data }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- หมายเลขทรัพย์สิน: {{ yay_data.e_number }} -->
          </b-modal>
        </div>

        <div v-else>
          <b-overlay :show="show" rounded="sm">
            <div class="row">
              <div class="col-12 col-md-4" v-for="(rows, index) in rows" :key="index">
                <b-card style="border: 1px solid #558cef">
                  <div class="text-center">
                    <div v-if="rows.e_image">
                      <!-- {{ rows.e_image }} -->
                      <swiper
                        ref="mySwiper"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="swiperOptions"
                      >
                        <swiper-slide v-for="(image_p, index) in rows.e_image" :key="index">
                          <b-img
                            max-width="100%"
                            max-height="50%"
                            src
                            :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                            class="mx-1 w-75 text-center"
                            style="height: 100%"
                          />
                        </swiper-slide>
                        <div slot="button-next" class="swiper-button-next" />
                        <div slot="button-prev" class="swiper-button-prev" />
                        <div slot="pagination" class="swiper-pagination" />
                      </swiper>
                    </div>
                    <div v-else>
                      <img
                        class="w-50"
                        src="https://t4.ftcdn.net/jpg/02/51/95/53/360_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg"
                        max-width="100%"
                        max-height="50%"
                        style="height: 100%"
                      />
                    </div>

                    <!-- <img
                      width="50%"
                      height="50%"
                      src
                      :onerror="`fetch('${API}files/image/${rows.e_image}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                      class="mx-1"
                    /> -->
                  </div>
                  <br />
                  <div>
                    <span><b>หมายเลขทรัพย์สิน :</b> {{ rows.e_number }}</span> <br />
                    <span><b>ชื่อทรัพย์สิน :</b> {{ rows.infoadmit }}</span>
                    <br />
                    <span><b>สถานะ :</b> {{ rows.status }}</span>
                    <br />
                    <span><b>สภาพ :</b> {{ rows.performance }}</span> <br />
                    <span><b>หมายเหตุ :</b> {{ rows.remark }}</span>
                  </div>

                  <br />
                  <!-- {{ rows.id }} -->
                  <b-button v-b-modal="`modal-user_count${rows.id}`" @click="user_count(rows)"> ตรวจนับ </b-button>
                  <b-modal
                    no-close-on-backdrop
                    :id="`modal-user_count${rows.id}`"
                    title="ตรวจนับทรัพย์สิน"
                    ok-title="ตรวจนับ"
                    cancel-title="ปิด"
                    @ok="user_count_connect"
                  >
                    <b-card-text>
                      <!-- {{ user_count_data.id }} -->
                      <div class="text-center" v-if="user_count_data">
                        <!-- {{ user_count_data.e_image }} -->
                        <!-- {{ image_edit_file }} -->
                        <div v-for="(image, index) in image_edit_file" :key="index">
                          <!-- {{ index }}
                          {{ image }} -->
                          <b-img class="w-50" max-width="50%" max-height="50%" :src="`data:image/png;base64,${image}`">
                          </b-img>
                          <!-- <b-img-lazy
                            width="150%"
                            height="150%"
                            src
                            :onerror="`fetch('${API}files/image/${image}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                            class="mx-1 delect_img m-1"
                          /> -->
                          <b-button @click="edit_image_del(image, index)" variant="danger" size="sm">
                            <feather-icon icon="XIcon"
                          /></b-button>
                        </div>
                      </div>
                      <b>หมายเลขทรัพย์สิน :</b> 03-001-0001/65 <br />
                      <b>รายการ :</b> โปรแกรมคอมพิวเตอร์
                      <b-form-radio-group v-model="status_im_value" class="d-flex" :options="status_im_">
                      </b-form-radio-group>

                      <div v-if="status_im_value == 'สูญหาย'">
                        <b-form-radio-group :options="performan_in_" class="d-flex" disabled> </b-form-radio-group>
                      </div>
                      <div v-else>
                        <b-form-radio-group v-model="performan_in_value" :options="performan_in_" class="d-flex">
                        </b-form-radio-group>
                      </div>
                      <div>
                        หมายเหตุ
                        <b-form-textarea
                          id="textarea"
                          v-model="remark_value"
                          placeholder="Enter something..."
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                      <br />
                      อัพโหลดรูปภาพ
                      <b-form-file
                        v-model="file_image"
                        placeholder="อัพโหลดรูปภาพ"
                        drop-placeholder="Drop file here..."
                        multiple
                        accept="image/*"
                      />
                    </b-card-text>
                  </b-modal>
                  <!-- <b-button> เลือกรายการ </b-button> -->
                </b-card>
              </div>
            </div>
            <!-- <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'remark'">
                  <span>
                    <b-input
                      v-model="props.row.remark"
                      @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                    />
                  </span>
                </span>

                <div v-else-if="props.column.field === 'status'">
                  <b-form-radio-group
                    class="p-1 d-flex"
                    :options="status_im"
                    v-model="props.row.status"
                    @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                  >
                  </b-form-radio-group>
                </div>

                <div v-else-if="props.column.field === 'performance'">
                  <span v-if="props.row.status === 'สูญหาย'">
                    <b-form-radio-group
                      disabled
                      :options="performan_im"
                      class="p-1 d-flex"
                      v-model="props.row.status"
                      @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                    >
                    </b-form-radio-group>
                  </span>
                  <span v-else>
                    <b-form-radio-group
                      :options="performan_im"
                      class="p-1 d-flex"
                      v-model="props.row.performance"
                      @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                    >
                    </b-form-radio-group
                  ></span>
                </div>
                <div v-else-if="props.column.field === 'e_image'">
                  <b-button variant="warning" v-b-modal="`modal-image${props.index}`">
                    <input ref="refInputEl" type="file" class="d-none" />
                    <feather-icon icon="ImageIcon" />
                  </b-button>
                  <b-modal
                    :id="`modal-image${props.index}`"
                    :title="`เพิ่มรูปภาพ${props.row.information}`"
                    cancel-title="ยกเลิก"
                    ok-title="ตกลง"
                    @ok="image_post(props.row)"
                  >
                    <b-card-text>
                      <b-form-file
                        v-model="file_image"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        multiple
                        accept="image/*"
                      />
                      <span>หมายเลขทรัพย์สิน: {{ props.row.e_number }}</span
                      ><br />
                      <span>สถานที่เก็บ: {{ props.row.place }}</span>
                    </b-card-text>
                  </b-modal>
                </div>

                <div v-else-if="props.column.field === 'c_image'">
                  <b-button
                    variant="warning"
                    v-b-modal="`modal-d_image${props.index}`"
                    @click="get_image(props.row, 2)"
                  >
                    <input ref="refInputEl" type="file" class="d-none" />
                    <feather-icon icon="ImageIcon" />
                  </b-button>
                  <b-modal
                    :id="`modal-d_image${props.index}`"
                    :title="`รูปภาพ${props.row.information}`"
                    cancel-title="ยกเลิก"
                    ok-title="ตกลง"
                  >
                    <b-card-text>
                      <div class="text-center">
                        <p>แสดงรูปภาพตรวจนับ</p>
                        <div v-if="image_p != ''">
                          <swiper
                            ref="mySwiper"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="swiperOptions"
                          >
                            <swiper-slide v-for="image_p in image_p" :key="image_p">
                              <b-img
                                width="250%"
                                height="250%"
                                src
                                :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                                class="mx-1"
                              />
                            </swiper-slide>
                            <div slot="button-next" class="swiper-button-next" />
                            <div slot="button-prev" class="swiper-button-prev" />
                            <div slot="pagination" class="swiper-pagination" />
                          </swiper>
                        </div>
                        <div v-else>ไม่มีรูป</div>
                      </div>

                      <span>หมายเลขทรัพย์สิน: {{ props.row.e_number }}</span
                      ><br />
                      <span>สถานที่เก็บ: {{ props.row.place }}</span>
                    </b-card-text>
                  </b-modal>
                </div>
                <div v-else-if="props.column.field === 'b_image'">
                  <b-button
                    variant="warning"
                    v-b-modal="`modal-aa_image${props.index}`"
                    @click="get_image(props.row, 3)"
                  >
                    <input ref="refInputEl" type="file" class="d-none" />
                    <feather-icon icon="ImageIcon" />
                  </b-button>
                  <b-modal
                    :id="`modal-aa_image${props.index}`"
                    :title="`รูปภาพ${props.row.information}`"
                    cancel-title="ยกเลิก"
                    ok-title="ตกลง"
                  >
                    <b-card-text class="text-center">
                      <p>แสดงรูปภาพลงทะเบียน</p>

                      <div v-if="image_p != ''">
                        <swiper
                          ref="mySwiper"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="swiperOptions"
                        >
                          <swiper-slide v-for="image_p in image_p" :key="image_p">
                            <b-img
                              width="250%"
                              height="250%"
                              src
                              :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                              class="mx-1"
                            />
                          </swiper-slide>
                          <div slot="button-next" class="swiper-button-next" />
                          <div slot="button-prev" class="swiper-button-prev" />
                          <div slot="pagination" class="swiper-pagination" />
                        </swiper>
                      </div>
                      <div v-else>ไม่มีรูป</div>

                      <span>หมายเลขทรัพย์สิน: {{ props.row.e_number }}</span
                      ><br />
                      <span>สถานที่เก็บ: {{ props.row.place }}</span>
                    </b-card-text>
                  </b-modal>
                </div>

                <div v-else>
                  {{ props.formattedRow[props.column.field] }}
                </div>
              </template>

              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="handlePagechange($event)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table> -->
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTab,
  BTabs,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { BFormDatepicker } from "bootstrap-vue";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import readXlsxFile from "read-excel-file";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import _ from "lodash";
import vSelect from "vue-select";

export default {
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    BFormDatepicker,
    BButton,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    Swiper,
    SwiperSlide,
    vSelect,
    BTab,
    BTabs,
    readXlsxFile,
  },
  data() {
    return {
      ckn1: false,
      ckn2: false,
      ckn3: false,
      ckn4: false,
      ckn5: false,
      selectedG: "เลือกกลุ่มทรัพย์สิน",
      selectedGs: [],
      selectedY: "เลือกย้ายรายการตรวจนับ",
      selectedYs: [],
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },
      image_view: "",
      image_p: "",
      file_image: [],
      result: "",
      error: "",
      API: `${API}`,
      status_im: [
        { text: "เลือก", value: "true" },
        { text: "ไม่เลือก", value: "false" },
      ],
      status_im_value: "",
      status_im_: [
        { text: "มี", value: "มี" },
        { text: "สูญหาย", value: "สูญหาย" },
      ],
      performan_in_value: "",
      performan_in_: [
        { text: "ใช้ได้", value: "ใช้ได้" },
        { text: "ใช้ไม่ได้", value: "ใช้ไม่ได้" },
        { text: "ชำรุด", value: "ชำรุด" },
      ],
      remark_value: "",
      from: [
        {
          datenow: null,
          note: null,
          situation: null,
          efficiency: null,
        },
      ],
      show: false,
      pageLength: 100,
      currentPage: 1,
      total: "",
      dir: false,

      pee: "",
      pees: [
        { value: "", text: "เลือกปีงบประมาณ:" },
        { value: "2559", text: "2559" },
        { value: "2560", text: "2560" },
        { value: "2561", text: "2561" },
      ],
      columns2: [
        {
          label: "ชื่อคนตรวจนับ",
          field: "name",
        },
        {
          label: "รายการ",
          field: "information",
        },
      ],
      columns3: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "ชื่อทรัพย์สิน",
          field: "infoadmit",
        },
        {
          label: "สถานที่เก็บ",
          field: "place",
        },
        {
          label: "สถานภาพ",
          field: "status",
        },
        {
          label: "สภาพ",
          field: "performance",
        },

        {
          label: "เลือกรายการตรวจนับ",
          field: "vgtSelected",
          width: "300px",
        },

        {
          label: "ย้ายรายการตรวจนับ",
          field: "yay",
        },
        {
          label: "ยกเลิกตรวจนับ",
          field: "delete_list",
        },
        // {
        //   label: "คนตรวจนับ",
        //   field: "คนตรวจนับ",
        // },
      ],
      columns: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "รายการ",
          field: "information",
        },

        {
          label: "สถานที่เก็บ",
          field: "place",
        },

        {
          label: "สถานภาพ",
          field: "status",
        },
        {
          label: "สภาพ",
          field: "performance",
        },
        {
          label: "หมายเหตุ",
          field: "remark",
        },
        {
          label: "เพิ่มรูปภาพ",
          field: "e_image",
        },
        {
          label: "แสดงรูปภาพตรวจนับ",
          field: "c_image",
        },
        {
          label: "แสดงรูปภาพลงทะเบียน",
          field: "b_image",
        },
      ],
      token: "",
      rows: [],
      alluser: [],
      data: {
        director_one: "",
        director_two: "",
        director_three: "",
      },
      Excel: [],
      searchTerm: "",
      status_admin: "",
      list_data_list: [],
      flavours: [
        { text: "เลือก", value: true },
        { text: "ไม่เลือก", value: false },
      ],
      yay_data: [],
      show_hidden: false,
      user_count_data: null,
      image_edit_file: [],
      image_edit: [],
      image_update: [],
      user_type: "",
    };
  },
  async created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    const { username, usertype } = await JSON.parse(localStorage.getItem("username_type"));
    console.log(username);
    console.log(usertype);
    this.status_admin = usertype;
    // this.status_admin = "user";
    // this.status_admin = username;
    // this.$router.push({ path: '/counting' }),
    console.log(this.$route.params.id.job_code);
    // _.isEmpty(this.$route.params.id.check_code) ? this.$router.push({ path: "/counting" }) : true;

    const { access_token } = await this.access_token();
    this.token = access_token;
    // this.get_data_row();
    this.get_data_table();
    // this.countingAll();
    this.item();
    this.jobList();

    // this.get_data_alluser();
    // this.post_data()
  },
  methods: {
    search_data(text) {
      console.log(text);
      console.log(this.rows);
      if (text) {
        const filteredItems = this.rows.filter((item) => item.e_number.includes(text));
        console.log(filteredItems);
        this.rows = filteredItems;
      } else {
        this.get_data_table();
        console.log("no text");
      }
    },
    async edit_image_del(image, index) {
      this.image_update = [];
      console.log(this.image_edit);
      const { access_token } = await this.access_token();
      const image_editFilter = [];
      const image_editFilter_Upload = [];
      this.image_edit_file.filter((item, index_) => {
        if (index_ != index) {
          // console.log(index_);
          image_editFilter.push(item);
        }
      });
      this.image_edit.filter((item, index_) => {
        if (index_ != index) {
          // console.log(index_);
          image_editFilter_Upload.push(item);
        }
      });
      this.image_edit = image_editFilter_Upload;
      // this.file_image = this.image_edit;
      this.image_edit_file = image_editFilter;
      console.log(this.image_edit);
      console.log(this.image_edit_file);
      return;
      this.image_edit_file.forEach(async (el, index) => {
        var u8 = new Uint8Array(el);
        var b64encoded = btoa(String.fromCharCode.apply(null, u8));
        const trimmedString = await b64encoded;
        const imageContent = atob(trimmedString);
        const buffer = new ArrayBuffer(imageContent.length);
        const view = new Uint8Array(buffer);
        for (let n = 0; n < imageContent.length; n++) {
          view[n] = imageContent.charCodeAt(n);
        }
        const type = "image/png";
        const blob = new Blob([buffer], { type });
        // this.image_update_upload = blob;
        var fileToUpload = new File([blob], `${el}.png`, {
          type,
          lastModified: new Date(),
        });
        this.file_image = [fileToUpload];
        console.log(fileToUpload);
      });
    },
    async user_count(rows) {
      console.log(rows);
      this.image_edit = [];
      this.file_image = [];
      this.user_count_data = rows;
      const { access_token } = await this.access_token();
      this.token = access_token;
      console.log(rows);
      this.status_im_value = rows.status ? rows.status : "";
      this.performan_in_value = rows.performance ? rows.performance : "";
      this.remark_value = rows.remark ? rows.remark : "";
      console.log(this.user_count_data);
      let b64 = [];
      let file_image_edit = [];
      rows.e_image.forEach(async (element, index) => {
        // this.dele_image.push(element);
        console.log(element);
        if (element) {
          const url_i = `${API}files/image/${element}`;
          const head_i = {
            responseType: "arraybuffer",
            headers: {
              Authorization: await access_token,
            },
          };
          const res_i = await axios.get(url_i, head_i);
          // console.log(res_i.data);
          var arrayBuffer = res_i.data;
          // var u8 = new Uint8Array(arrayBuffer);
          //var b64encoded = btoa(String.fromCharCode.apply(null, u8));
          // console.log(b64encoded);
          //
          var u8 = new Uint8Array(arrayBuffer);
          var b64encoded_ = btoa(String.fromCharCode.apply(null, u8));
          b64.push(b64encoded_);
          const trimmedString = await b64encoded_;
          const imageContent = atob(trimmedString);
          const buffer = new ArrayBuffer(imageContent.length);
          const view = new Uint8Array(buffer);
          for (let n = 0; n < imageContent.length; n++) {
            view[n] = imageContent.charCodeAt(n);
          }
          const type = "image/png";
          const blob = new Blob([buffer], { type });
          var fileToUpload = new File([blob], `${element}.png`, {
            type,
            lastModified: new Date(),
          });

          this.image_edit.push(fileToUpload);
          console.log(this.image_edit);
          this.image_edit_file = b64;
          return;
          file_image.push(fileToUpload);
        }
      });
      return;
      console.log(b64);
      const file_image = [];
      b64.forEach(async (el, index) => {
        console.log(el);
        var u8 = new Uint8Array(el);
        var b64encoded_ = btoa(String.fromCharCode.apply(null, u8));
        const trimmedString = await b64encoded_;
        const imageContent = atob(trimmedString);
        const buffer = new ArrayBuffer(imageContent.length);
        const view = new Uint8Array(buffer);
        for (let n = 0; n < imageContent.length; n++) {
          view[n] = imageContent.charCodeAt(n);
        }
        const type = "image/png";
        const blob = new Blob([buffer], { type });
        // this.image_update_upload = blob;
        var fileToUpload = new File([blob], `${el}.png`, {
          type,
          lastModified: new Date(),
        });
        file_image.push(fileToUpload);
      });
      console.log(file_image);
    },
    async user_count_connect() {
      console.log(this.user_count_data);
      console.log(this.status_im_value);
      console.log(this.performan_in_value);
      const { access_token } = await this.access_token();

      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      console.log(this.user_count_data);
      const url = `${API}jobListsItem/${this.user_count_data.id}`;
      console.log(url);
      const { dep_id, fullname } = await JSON.parse(localStorage.getItem("username_type"));

      let data = new FormData();
      data.append("job_code", this.user_count_data.job_code);
      data.append("e_number", this.user_count_data.e_number);
      data.append("status", this.status_im_value);
      data.append("performance", this.status_im_value === "สูญหาย" ? this.status_im_value : this.performan_in_value);
      data.append("remark", this.remark_value);
      // data.append("e_image", this.file_image);

      data.append("director1_name", fullname);
      data.append("director1_position", dep_id);

      console.log(data);

      const res = await axios.put(url, data, head);
      console.log(res);

      let data_image = new FormData();
      const url_image = `${API}equipmentDetail/${this.user_count_data.e_number.replace("/", "_")}`;
      console.log(this.image_edit);

      // if(this.file_image){
      //   this.image_edit = []
      // }

      if (this.image_edit) {
        this.file_image.push(...this.image_edit);
      }

      if (this.file_image.length == 0) {
        data_image.append("e_image", []);
      }
      this.file_image.forEach((element) => {
        data_image.append("e_image", element);
      });
      const res_image = await axios.put(url_image, data_image, head);
      console.log(res_image);
      this.get_data_table();
    },
    get_yay(value) {
      this.yay_data = [];
      this.yay_data.push(value);
    },
    async get_yay_connect(value) {
      // console.log(value);
      // console.log(this.yay_data);
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      this.yay_data.forEach(async (el) => {
        console.log(el);
        const url = `${API}jobListsItem/${el.id}`;
        const data = {
          job_code: value.job_code,
          e_number: el.e_number,
        };
        const res = await axios.put(url, data, head);
        console.log(res);
      });
      this.get_data_table();
      return;

      const url = `${API}jobListsItem/${this.yay_data.id}`;
      const data = {
        job_code: value.job_code,
        e_number: this.yay_data.e_number,
      };
      console.log(data);

      re;

      console.log(res);
      Swal.fire({
        icon: "success",
        title: "ย้ายข้อมูลสำเร็จ!",
        text: `ย้ายข้อมูลเเล้ว`,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },

    ch1_k(rows) {
      this.ckn1 == true ? (this.ckn1 = false) : (this.ckn1 = true);
      console.log(this.ckn1);
      console.log(this.rows);

      this.rows = rows.map((el) => {
        return {
          ...el,
          vgtSelected: (el.vgtSelected = this.ckn1),
        };
      });
      console.log(rows);

      // this.get_data_table();
    },
    ckn2_k(rows) {
      this.ckn2 == true ? (this.ckn2 = false) : (this.ckn2 = true);

      this.rows = rows.map((el) => {
        return {
          ...el,
          vgtSelected:
            el.performance === "" || el.status === "" || el.performance === " " || el.status === " "
              ? this.ckn2
              : false,
        };
      });
    },
    ckn3_k(rows) {
      this.ckn3 == true ? (this.ckn3 = false) : (this.ckn3 = true);
      this.rows = rows.map((el) => {
        return {
          ...el,
          vgtSelected: el.job_code === "" ? this.ckn3 : false,
        };
      });
    },
    async ckn4_k(rows) {
      this.yay_data = [];
      rows.map((el) => {
        if (el.vgtSelected === true) {
          this.yay_data.push(el);
        }
        console.log(el);
        console.log(el.vgtSelected);
      });
      console.log(this.yay_data);
    },
    ckn5_k(rows) {
      this.ckn5 == true ? (this.ckn5 = false) : (this.ckn5 = true);

      this.rows = rows.map((el) => {
        return {
          ...el,
          vgtSelected:
            el.performance === "มี" ||
            el.performance === "สูญหาย" ||
            el.status == "" ||
            el.performance != " " ||
            el.status != " "
              ? this.ckn5
              : false,
        };
      });
    },
    async condel(row, value) {
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      console.log(row);
      console.log(value);
      Swal.fire({
        title: "คุณต้องการจะยกเลิกข้อมูลใช่หรือไม่",
        text: `ยกเลิกที่เลือก`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },

        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          if (value == "ยกเลิกรายการ") {
            console.log("ยกเลิกรายการ");
            const url = `${API}jobItemsRemove/${row.id}`;
            const res = axios.delete(url, head);
            console.log(res);
            this.get_data_table();
          } else if (value == "ยกเลิกที่เลือกทั้งหมด") {
            console.log("ยกเลิกที่เลือกทั้งหมด");
            row.forEach((el) => {
              if (el.vgtSelected == true) {
                console.log(el);
                const url = `${API}jobItemsRemove/${el.id}`;
                const res = axios.delete(url, head);
                console.log(res);
              }
            });
            this.get_data_table();
          }

          Swal.fire({
            icon: "success",
            title: "ยกเลิกข้อมูลสำเร็จ!",
            text: `ยกเลิกข้อมูลที่เลือกเเล้ว`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          // this.material_list();
        }
      });
    },
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        const xx = rows.filter((element, index) => {
          return index >= 2;
        });
        console.log(xx);
        const num = {};
        const doubly = {};

        const uu = xx.filter((res, index) => {
          let empty = true;
          res.map((res2, index) => {
            if (res2 === null || res2 === "null" || res2 === undefined || res2 === "undefined" || res2 === "") {
              empty = false;
            }

            if (index === 1) {
              if (num[res2]) {
                num[res2]++;
                doubly[res2] = res;
              } else {
                num[res2] = 1;
              }
            }
          });
          return empty && res;
        });
        // console.log(uu);
        // console.log(doubly);
        if (!!!_.isEmpty(doubly)) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `ประเภททรัพย์สินซ้ำ ${Object.values(doubly)}`,
            showConfirmButton: true,
          });
          this.Excel = uu;
        } else {
          this.Excel = uu;
        }
      });
    },
    async get_data_alluser() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=1&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      const res_user = res.data.message.data.map((rs) => {
        return {
          ...rs,
          firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
        };
      });
      this.alluser = res_user;
      // console.log(res.data.message);
    },
    async item() {
      const { access_token } = await this.access_token();
      const workgroups = `${API}equipmentGroups?_limit=100&_sort=1`;
      const header_workgroups = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_workgroups = await axios.get(workgroups, header_workgroups);
      // console.log(res_workgroups);
      this.selectedGs = res_workgroups.data.message.data.map((element) => {
        return {
          ...element,
          e_gname: `${element.e_gid} - ${element.e_gname}`,
        };
      });
    },

    async jobList() {
      const { access_token } = await this.access_token();
      const url = `${API}jobList?_limit=100&_sort=1`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      // console.log(res_workgroups);

      this.selectedYs = res.data.message.data.map((element) => {
        console.log(element);
        if (this.$route.params.id.job_code != element.job_code) {
          return {
            ...element,
            checker_name: element.checker_name.replace(/'/g, "").split(","),

            e_gname: `ID Job: ${element.job_code} - ชื่อ Job: ${element.code}`,
          };
        }
      });
    },

    async item_select(params) {
      // console.log(params.e_gid);
      console.log(params);
      this.rows = [];

      if (this.selectedG) {
        this.show_hidden = params ? true : false;
        this.show = true;
        const { access_token } = await this.access_token();
        this.e_gid = params.e_gid;
        const url = `${API}withdrawList?e_gid=${this.e_gid}&e_status=false&_limit=1`;
        let head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res_data = await axios.get(url, head);
        this.material = [];
        const tt = Math.ceil(res_data.data.message.total / 100);
        // console.log(tt);
        const list_data = [];
        for (let index = 1; index <= tt; index++) {
          // console.log(index);
          const url = `${API}withdrawList?e_gid=${this.e_gid}&e_status=false&_limit=100&_page=${index}`;
          const res_data = await axios.get(url, head);
          // console.log(res_data.data.message.data);
          res_data.data.message.data.map((el) => {
            list_data.push(el.e_number);
          });
          // console.log(list_data);
          this.list_data_list = [];
          this.list_data_list = list_data;
          // console.log(this.list_data_list);
          this.get_data_row();
          // this.rows.push(...res_data.data.message.data);
          // this.get_data_table();
          // console.log(this.rows);
        }
        this.show = false;
      } else {
        this.get_data_table();
      }
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "รายการตรวจนับทรัพย์สิน",
        columns: [
          {
            title: "รายการตรวจนับทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "หมายเลขทรัพย์สิน",
                field: "e_number",
              },
              {
                title: "รายการ",
                field: "information",
              },

              {
                title: "สถานที่เก็บ",
                field: "place",
              },

              {
                title: "สถานภาพ",
                field: "status",
              },
              {
                title: "สภาพ",
                field: "performance",
              },
              {
                title: "หมายเหตุ",
                field: "remark",
              },
            ],
          },
        ],
      });
    },
    async get_image() {
      return;
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      console.log(this.rows);

      this.rows.forEach(async (element, index) => {
        // this.dele_image.push(element);
        console.log(element.e_image);
        if (element.e_image) {
          const url_i = `${API}files/image/${element.e_image}`;
          const head_i = {
            responseType: "arraybuffer",
            headers: {
              Authorization: await access_token,
            },
          };
          const res_i = await axios.get(url_i, head_i);
          var arrayBuffer = res_i.data;
          var u8 = new Uint8Array(arrayBuffer);
          var b64encoded = btoa(String.fromCharCode.apply(null, u8));
          console.log(b64encoded);
        }
      });
    },

    async image_post(value) {
      // console.log('2');
      const { e_number, check_code } = value;
      const { access_token } = await this.access_token();

      const url = `${API}equipmentImageCheck/${e_number.replace("/", "_")}&${check_code}`;
      let data = new FormData();
      this.file_image.forEach((element) => {
        // console.log(element);
        data.append("e_image", element);
      });
      // data.append('e_image', this.file_image[0]);

      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.put(url, data, head);
      // console.log(res);
      // console.log(this.file_image.length);
      this.get_data_table();
    },
    onDecode(result) {
      this.result = result;
      this.searchTerm = this.result;
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
    async countingAll() {
      this.show = true;
      // console.log(this.$route.params.id);
      const { access_token } = await this.access_token();
      const url = `${API}countingAll?check_code=${this.$route.params.id.check_code}&work_gid=${this.$route.params.id.work_gid}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      // console.log(url);
      const response = await axios.post(url, "", head);

      // console.log(head, url);
      if (response.data.status === 200) {
        this.get_data_table();
      } else {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: `${response.data.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.show = false;
    },
    async post_data() {
      const { access_token } = await this.access_token();
      let head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const { dep_id, fullname } = await JSON.parse(localStorage.getItem("username_type"));
      this?.rows?.forEach(async (element) => {
        if (element.vgtSelected == true) {
          console.log(element);
          console.log(this.$route.params.id.job_code);
          console.log(this.$route.params.id.code);

          const data_ = new FormData();
          data_.append("job_code", this.$route.params.id.job_code);
          data_.append("e_number", element.e_number);
          data_.append("information", " ");
          data_.append("place", " ");
          data_.append("remark", " ");
          data_.append("status", " ");
          data_.append("performance", " ");
          data_.append("e_image", " ");

          data_.append("director1_name", fullname);
          data_.append("director1_position", dep_id);
          data_.append("director2_name", " ");
          data_.append("director2_position", " ");
          const url_a = `${API}jobListsItem`;
          console.log(data_);
          const res_a = await axios.post(url_a, data_, head);
          console.log(res_a);
          if (res_a[0].data.status === 200) {
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: "บันทึก",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              position: "top-center",
              icon: "error",
              title: res_a[0].data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          console.log(element.vgtSelected);
        }
      });
      this.selectedG = null;
      this.get_data_table();
      const url = `${API}updateItemStatus/`;

      var aaa;
      var sss;
      // this?.rows?.forEach(async (element) => {
      //   console.log(element)
      //   const data = new FormData();
      //   data.append("remark", element.remark);
      //   data.append("status", element.status);
      //   data.append("performance", `${element.status === "สูญหาย" ? "สูญหาย" : element.performance}`);
      //   data.append("e_image", element.e_image);
      //   aaa = await axios.put(url + element.id, data, head);
      //   sss++;
      // });
      Swal.fire({
        position: "top-center",
        icon: "success",
        title: "บันทึก",
        showConfirmButton: false,
        timer: 1500,
      });

      // console.log(sss++);
    },
    changeCell(vaule, column, row, vgtSelected) {
      console.log(vaule, column, row, vgtSelected);
      console.log(row);
      this.rows[row][column] = vaule;
      // console.log(this.rows[row][column]);
    },
    handlePagechange(event) {
      this.currentPage = event;
      this.get_data_table();
    },
    handleChangePage(event) {
      this.pageLength = event;
      this.get_data_table();
    },
    async get_data_row() {
      // this.rows = []
      const { access_token } = await this.access_token();
      // console.log(this.e_gid)
      // console.log(this.list_data_list.join(','))
      // console.log(this.$route.params.id);
      // console.log(this.list_data_list);
      const myString = this.list_data_list.map((element) => `'${element}'`).join(",");
      // console.log(myString);
      this.show = true;

      const url = `${API}equiptmentCheckList?e_number=${myString}&_limit=100&_sort=1&_page=1`;
      const url_lis = url;
      // console.log(url_lis);
      let head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message);
      const res_total = Math.ceil(Number(res.data.message.total) / 100);
      // console.log(res_total)
      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(`${API}equiptmentCheckList?e_number=${myString}&_limit=100&_sort=1&_page=${i}`);
        // console.log(url_link);
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i], head)).data.message.data];
      }
      // console.log(res_data_);
      // const created_data = res.data.message.data.map((res) => {
      //   return {
      //     check_code: this.$route.params.id.check_code,
      //     information: null,
      //     status: null,
      //     performance: '',
      //     ...res,
      //   }
      // })
      const aaa = res_data_.map((el) => {
        // console.log(el);
        return {
          ...el,
          checker_name: el.checker_name ? el.checker_name.replace(/'/g, "").split(",") : "",
          job_code: el.job_code ? el.job_code : "",
          vgtSelected: false,
        };
      });
      this.rows = aaa;
      // console.log(this.rows);
      this.total = res.data.message.total;
      this.show = false;
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}jobListsItem?job_code=${this.$route.params.id.job_code}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      const res_total = Math.ceil(Number(res.data.message.total) / 100);
      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(`${API}jobListsItem?job_code=${this.$route.params.id.job_code}&_limit=100&_page=${i}`);
        // console.log(url_link);
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i], head)).data.message.data];
      }
      // console.log(res_data_);
      const myString = res_data_.map((element) => `'${element.e_number}'`).join(",");
      // console.log(myString);

      const url_ = `${API}equiptmentCheckList?e_number=${myString}&_limit=100&_sort=1`;
      const res_ = await axios.get(url_, head);
      // console.log(res_);
      const res_total_ = Math.ceil(Number(res_.data.message.total) / 100);
      const url_link_ = [];
      for (let i = 1; i <= res_total_; i++) {
        url_link_.push(`${API}equiptmentCheckList?e_number=${myString}&_limit=100&_sort=1&_page=${i}`);
        // console.log(url_link_);
      }
      let res_data__ = [];
      for (let i in url_link_) {
        res_data__ = [...res_data__, ...(await axios.get(url_link_[i], head)).data.message.data];
      }
      // console.log(res_data__);

      this.rows = res_data__.map(async (el) => {
        const url = `${API}equipmentDetail?e_number=${el.e_number}`;
        const res = await axios.get(url, head);
        console.log(res.data.message.data[0]);
        return {
          ...el,
          e_image: res.data.message.data[0].e_image ? JSON.parse(res.data.message.data[0].e_image) : "",
          checker_name: el.checker_name.replace(/'/g, "").split(","),
          job_code: `${el.job_code}`,
          vgtSelected: false,
        };
      });
      Promise.all(this.rows)
        .then((results) => {
          console.log(results);
          this.rows = results;
        })
        .catch((error) => {
          console.error(error);
        });
      // console.log(res_);
      this.total = res.data.message.total;

      this.show = false;
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },

    getfrom() {
      (this.result = ""), (this.error = "");
    },
  },
};
</script>
<style lang="scss">
.error {
  font-weight: bold;
  color: red;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
